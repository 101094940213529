<template>
  <div
    v-if="supportApplePay"
    class="apple-pay-btn"
    :style="propsStyle"
  >
    <apple-pay-button
      buttonstyle="black"
      type="pay"
      :locale="applePayBtnLocale"
      :style="{
        '--apple-pay-button-width': propsStyle.width,
        '--apple-pay-button-height': propsStyle.height
      }"
      @click="handleBtnClick"
    />
  </div>
</template>
<script>
import { asyncLoadFile } from '@shein/common-function'
import { SPECIAL_APPLPE_PAY_LOCALES, DEFAULT_APPLPE_PAY_LOCALE } from '@shein-aidc/bs-sdk-libs-pay'
const IS_SSR = typeof window == 'undefined'
const { lang } = IS_SSR ? {} : window.gbCommonInfo

export default {
  name: 'ApplePayBtn',
  props: {
    propsStyle: {
      default: () => {},
      type: Object
    },
    supportApplePay: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingStatus: false,
      applePayBtnLocale: 'en-US'
    }
  },
  mounted() {
    // 使用 Vue.nextTick 确保 DOM 更新完成
    this.$nextTick(() => {
      if (!this.supportApplePay) return
      asyncLoadFile({
        label: 'script',
        attrs: {
          src: 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js'
        },
        inBody: false
      })
        .then(() => {
          this.loadingStatus = false
          // 找到所有标签名为 apple-pay-button 的元素
          let applePayButtons =
            document.getElementsByTagName('apple-pay-button')
          // 遍历所有找到的元素
          for (let i = 0; i < applePayButtons.length; i++) {
            let button = applePayButtons[i]
            button.removeAttribute('hidden')
            button.removeAttribute('aria-hidden')
            button.style.display = 'block'
          }
        })
        .catch(err => {
          this.loadingStatus = false
        })
    })
    this.applePayBtnLocale = SPECIAL_APPLPE_PAY_LOCALES[lang || ''] || DEFAULT_APPLPE_PAY_LOCALE
  },
  methods: {
    handleBtnClick() {
      if (this.disabled) return
      this.$emit('handleBtnClick', true)
    }
  }
}
</script>
<style scoped>
.apple-pay-btn {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 100%;
  --apple-pay-button-border-radius: 0px;
  --apple-pay-button-padding: 7px 0px;
  --apple-pay-button-box-sizing: border-box;
}
</style>
