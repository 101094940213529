import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'

class NewBffUtils {
  constructor() {
    this.switchData = {}
    this.defaultKeywords = [
      'payCod_v1',
      'checkout_v1',
      'oneClickPay_v1',
    ]
    this.initMap()
  }

  initMap(version = this.defaultKeywords) {
    let res = isSwitchBffApiVersion(version)
    Object.assign(this.switchData, res)
  }
  addVersion(version) {
    this.initMap(version)
  }
  // 检查是否开启新bff, 只要满足其中一个feature开启，就返回true
  checkNewBff(features) {
    return features.some(feature => {
      return this.switchData[feature]
    })
  }
  paramsToFormData(params) {
    const formData = new FormData()
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        formData.append(key, params[key])
      }
    }
    return formData
  }
}

export default new NewBffUtils()
